<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="profile-switch" slot="component">
      <p class="profile-switch-pin-title">{{ $t("Enter PIN To Switch Profile") }}</p>
      <div class="profile-switch-pin">
        <form @submit.prevent="submitSwitchProfile">
          <div class="form-group">
            <div class="error-container" v-if="formError">
              <p class="error">{{ formError }}</p>
            </div>
            <div class="form-control profile-switch-pin-box">
              <label>{{ $t("Enter PIN") }}</label>
              <div dir="ltr" id="pininputs" class="profile-switch-pin-box-inputs">
                <input
                  v-for="(item, index) in numberOfOtpBlocks"
                  :key="index"
                  class="pin"
                  type="password"
                  v-model="userPin[item - 1]"
                  @input="grabPin"
                  maxlength="1"
                  inputmode="numeric"
                />
              </div>
            </div>
          </div>
          <p @click="onForgotPin" class="forgot-pin">{{ $t("Forgot PIN?") }}</p>
          <div>
            <button
              class="button-primary "
              :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
              :disabled="isLoading"
            >
              <Loading v-if="isLoading" :size="'16px'"></Loading>
              <span>{{ $t("submit") }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </Popup>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { _providerId } from "@/provider-config.js";
import { mapActions, mapGetters, mapMutations } from "vuex";

import snackbar from "node-snackbar";
import "../../../../node_modules/node-snackbar/dist/snackbar.css";
import { showSnackBar } from '../../../utilities';
import jwt_decode from "jwt-decode";
import { getFreshTokenUpdate, actSaveExpireTime } from '@/store/refershTokenInterval'

export default {
  props: {
    closePopupEvent: {
      type: Function,
    },

    profile: {
      type: Object,
    },
  },

  data() {
    return {
      width: "30%",
      margin: "15% auto",
      radiusType: "full",
      userPin: [],
      profilePin: null,
      formError: null,
      providerUniqueId: _providerId,
      numberOfOtpBlocks: 4,
      isLoading: false,
    };
  },

  watch: {
    formError() {
      setTimeout(() => {
        this.formError = null;
      }, 2000);
    },
  },

  computed: {
    ...mapGetters(["getPlanDeeplinkPath", "subscriberid", "getPairingFlag"])
  },

  mounted() {
    this.setupPin();
  },

  methods: {
    ...mapActions(["switchProfile", "fcmMessagingSubscribeToTopic", "parentalControlStatus"]),

    ...mapMutations(["setProfileid", "setKidsMode"]),

    setupPin() {
      setTimeout(() => {
        this.getId = document.getElementById("pininputs");
        this.pinFunctionality(this.getId);
      }, 1000);
    },

    onForgotPin(){
      this.$emit("onForgotPin", this.profile);
    },
    grabPin() {
      if (this.userPin.length > 3) {
        let pin = "";
        this.userPin.forEach((element) => {
          pin += element;
        });
        this.profilePin = pin;
      }
    },

    submitSwitchProfile() {
      this.isLoading = true;

      if (!this.profilePin || (this.profilePin && this.profilePin.length < this.numberOfOtpBlocks)) {
        this.formError = this.$t("Please enter the Pin");
        this.isLoading = false;

        return;
      }

      const profilePayload = {
        profileid: this.profile.profileid,
        pin: {
          profilepin: this.profilePin,
        },
      };

      this.switchProfile(profilePayload)
        .then((response) => {
          if (!response.data.reason) {
            this.isLoading = false;
            localStorage.setItem("sessionToken", response.data.success);
            localStorage.setItem("refreshtoken", response.data.refreshtoken);

            try {
              actSaveExpireTime(jwt_decode(response.data.success).expiresIn)
              localStorage.setItem("refresh-token-expire",jwt_decode(response.data.success).expiresIn);
              getFreshTokenUpdate();

              this.parentalControlStatus()
                .then((data) => {
                  if (data.reason) {
                    return;
                  }
                  if (data.data.isenabled) {
                    localStorage.setItem("parentalControlEnabled", data.data.isenabled);
                  }
                });
            } catch (e) {}             


            localStorage.setItem("currentProfileId", profilePayload.profileid);
						localStorage.setItem("currentProfileIdAdminCheck", profilePayload.profileid);
            localStorage.setItem("subscriberProfileDetails", JSON.stringify(this.profile));
            localStorage.setItem("isKidsMode", this.profile.kidsmode === "YES");


            this.setProfileid(profilePayload.profileid);
            let kidsMode = profilePayload.kidsmode === "NO" ? false: true;
            this.setKidsMode(kidsMode);

            
            this.fcmMessagingSubscribeToTopic(this.subscriberid);

						let currentLanguage = this.getCurrentLanguageUrlBase();
             let defaultLanguage = window.PROVIDER_SETUP_CONFIG.defaultLanguage;
            if (currentLanguage == defaultLanguage) {
                currentLanguage = null;
              }
              if(this.getPairingFlag) {
               this.$router.push({ name: "loginWithPinView"} );
              } else {
                this.$router.push({path: window.storeAfterLoginPath ? window.storeAfterLoginPath : currentLanguage ? '/'+ currentLanguage : '/' });

                // this.$router.push({ name: window.storeAfterLoginPath ? window.storeAfterLoginPath.name : "Home", params: { lang: currentLanguage } });
                // window.storeAfterLoginPath = ""
              }  
          } else {
            this.userPin = [];
            this.profilePin = "";

            this.formError = this.$t(response.data.reason);


            this.isLoading = false;
          }
        }).catch((err) => {
          this.isLoading = false;
        });
    },

    validateOtp(pin) {
      this.formError = null;
      if (!pin) {
        this.formError = "Please enter pin";
        return false;
      }
      return true;
    },

    closePopup() {
      console.log("CLOSE POPUP");

      this.$emit("closeProfilePinPopup");
    },
  },

  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },

  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./switchProfilePin.scss";

.button-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
</style>
